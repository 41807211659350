<template>
    <div>
        <Sidebar />
        <Navbar />
        <v-container fluid>
            <v-row class="px-5">
                <v-col cols="12" md="8" lg="8">
                    <h3 class="topic">Cases</h3>
                    <v-row class="my-4">
                        <v-col cols="12" md="4" lg="4">
                            <v-card
                            color="#023E7D"
                            flat
                            @click="openCard"
                            >
                                <v-card-text class="pt-2">
                                    <div class="help_text">
                                        <span>Find new cases related to your registered location and practice area.</span>
                                    </div>
                                    <div class="d-flex justify-start align-center">
                                        <v-avatar rounded size="34" color="#DCE8F8" class="me-4">
                                            <v-icon color="#023E7D" dark>
                                            mdi-briefcase
                                            </v-icon>
                                        </v-avatar>
                                        <div class="mr-auto">
                                            <div class="prospective_figure">{{attorneyCases?.prospectiveCount}}</div>
                                            <span class="prospective_text">Prospective Case</span>
                                        </div>
                                        <!-- <v-btn
                                            icon
                                            color="#DCE8F8"
                                            @click="NewCasesDialog = true"
                                        >
                                            <v-icon>mdi-arrow-right-circle</v-icon>
                                        </v-btn> -->
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="4" lg="4">
                            <v-card
                            color="#B7EBF1"
                            flat
                            @click="matchedCard"
                            >
                                <v-card-text class="pt-10 text-end">
                                    <h2 class="card_figure">{{attorneyCases?.matchedCount}}</h2>
                                    <span class="card_figure-text text-caption">Matched Cases</span>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="4" lg="4">
                            <v-card
                            color="#FAEDD9"
                            flat
                            @click="retainedCard"
                            >
                                <v-card-text class="pt-10 text-end">
                                    <h2 class="card_figure">{{attorneyCases?.retainedCount}}</h2>
                                    <span class="card_figure-text text-caption">Retained Cases</span>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <h4 class="topic mb-3">Recent Cases</h4>
                    <div class="d-flex justify-space-between align-center">
                        <v-text-field
                            v-model="search"
                            rounded
                            filled
                            dense
                            label="Search case description"
                            append-icon="mdi-magnify"
                            :hide-details="true"
                            class="some-style"
                        ></v-text-field>
                        <div class="ms-4">
                            <v-chip
                                class="pe-0 mr-2 text-capitalize"
                                color="#DCE8F8"
                            >
                                {{ caseType[selectedType] }}
                                <v-menu offset-y>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                    icon
                                    small
                                    class="ms-2"
                                    v-bind="attrs"
                                    v-on="on"
                                    >
                                    <v-icon small>mdi-chevron-down</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item-group
                                        v-model="selectedType"
                                        color="primary"
                                    >
                                    <v-list-item
                                    v-for="(item, i) in caseType"
                                    :key="i"
                                    >
                                    <v-list-item-title class="text-capitalize">{{ item }}</v-list-item-title>
                                    </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                                </v-menu>
                            </v-chip>
                            <v-btn
                            icon
                            small
                            @click="sort = !sort"
                            >
                            <v-icon small>{{sort  ? 'mdi-order-bool-descending' : 'mdi-order-bool-ascending'}}</v-icon>
                            </v-btn>
                        </div>
                    </div>
                    
                    <v-row class="my-1">
                        <v-col cols="12">
                            <v-card 
                            height="400"
                            flat
                            >
                                <div v-if="caseLoader">
                                    <v-skeleton-loader
                                    type="list-item-avatar-two-line"
                                    ></v-skeleton-loader>
                                </div>
                                <v-responsive
                                v-else-if="attorneyCases?.data?.length > 0 && !caseLoader"
                                class="overflow-y-auto"
                                max-height="390"
                                >
                                    <v-responsive>
                                        <div
                                        v-for="(item, i) in attorneyCases?.data"
                                        :key="i"
                                        >
                                            <v-card flat class="mt-2 attorney_card d-flex justify-start" @click="selectedCase(item, i)">
                                                <div 
                                                class="case_link flex-grow-1 flex-shrink-0" 
                                                style="min-width: 100px; max-width: 100%;"
                                                >
                                                <div class="attorney_card-box d-flex align-center">
                                                    <v-avatar size="34" color="#DCE8F8" class="me-4">
                                                        <v-icon color="#C3D6EF" dark>
                                                        mdi-briefcase
                                                        </v-icon>
                                                    </v-avatar>
                                                    <span class="text-capitalize">{{item?.case_name}}</span>
                                                </div>
                                                </div>
                                                <v-chip color="#dee2e6" class="pe-0">
                                                    <span class="me-4 text-capitalize">{{item?.status}}</span>
                                                    <v-progress-circular
                                                    v-if="loadingState[i]"
                                                    :indeterminate="loadingState[i]"
                                                    color="#023E7D"
                                                    :size="15"
                                                    :width="3"
                                                    class="ms-1 me-2"
                                                    ></v-progress-circular>
                                                    <v-icon v-else color="#023E7D" class="mx-1">mdi-chevron-right</v-icon>
                                                </v-chip>
                                            </v-card>
                                        </div>
                                    </v-responsive>
                                </v-responsive>
                                <div v-else-if="attorneyCases?.data?.length === 0 && !caseLoader" class="text-center d-flex justify-center">
                                    <v-card flat width="400" height="390" class="text-center d-flex align-center">
                                        <div>
                                            <v-icon
                                            size="100"
                                            color="#D4D4D4"
                                            >
                                            mdi-package-variant
                                            </v-icon>
                                            <p class="placeholder_text">Nothing to see here! You don't have any case, create a case and it will show up on this list.</p>
                                        </div>
                                    </v-card>
                                </div>
                            </v-card>
                            <div class="py-2 d-flex justify-end">
                                <v-pagination
                                v-model="page"
                                :length="attorneyCases.totalPages"
                                circle
                                color="#023E7D"
                                ></v-pagination>
                            </div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="4" lg="4" class="right_sidebar">
                    <ApptCard />
                    <TranCard />
                </v-col>
                
            </v-row>
        </v-container>

        <!-- new cases dialog -->
        <!-- <v-dialog
        v-model="NewCasesDialog"
        max-width="700"
        >
        <v-card>
            <v-card-title class="card_title font-weight-bold">
            Your prospective client cases.
            </v-card-title>
            <v-card-text class="pt-4">
                <v-row>
                    <v-col cols="12">
                        <v-carousel hide-delimiters v-model="attorneySlide">
                            <v-carousel-item
                            v-for="(item, i) in attorneyCases"
                            :key="i"
                            >
                            <v-sheet
                                color="white"
                                height="100%"
                                tile
                            >
                                <v-row
                                class="fill-height"
                                align="center"
                                justify="center"
                                >
                                <v-card
                                    class="mx-auto"
                                    width="500"
                                    elevation="2"
                                >
                                    <div class="pa-4 card_header white--text d-flex justify-space-between">
                                        <div>
                                            <h3 class="text-capitalize">{{item.client.first_name}} {{item.client.last_name}}</h3>
                                            <span
                                            class="text-capitalize"
                                            >{{ item.case_type }}</span>
                                        </div>
                                        <v-btn
                                            icon
                                            color="#fff"
                                            @click="viewItem(item)"
                                            >
                                            <v-icon>mdi-information-outline</v-icon>
                                        </v-btn>
                                    </div>
                                    <v-card-text>
                                    <v-row>
                                        <v-col cols="12">
                                            <h5>Case Name</h5>
                                            <div class="profile_text">{{item.case_name}}</div>
                                        </v-col>
                                        <v-col cols="6">
                                            <h5>Date of Incident</h5>
                                            <div class="profile_text">{{item.date_of_incident | formatDate}}</div>
                                        </v-col>
                                        <v-col lg="6">
                                            <h5>Incident Location</h5>
                                            <div class="profile_text">{{item.location_incident}}</div>
                                        </v-col>
                                        <v-col lg="12">
                                            <h5>Case Summary</h5>
                                            <div class="profile_text">{{item.case_description}}</div>
                                        </v-col>
                                    </v-row>
                                    </v-card-text>
                                    <v-card-actions class="pa-4 d-flex justify-space-between">
                                        <div>
                                            <div class="mb-1 d-flex justify-start align-center">
                                                <div v-show="item.geocoder.road_travel_time_hours != 'you may need to swim....'" class="d-flex align-center">
                                                    <v-icon class="me-2 profile_icon" color="#7E889A">
                                                        mdi-car
                                                    </v-icon>
                                                    <span v-if="item.geocoder.road_travel_time_hours != 0" class="me-3 profile_icon-text">{{ item.geocoder.road_travel_time_hours }}hrs</span>
                                                    <span v-else class="me-3 profile_icon-text">{{ item.geocoder.road_travel_time_hours }}mins</span>
                                                </div>
                                                <div class="d-flex align-center">
                                                    <v-icon class="me-2 profile_icon" color="#7E889A">
                                                        mdi-airplane
                                                    </v-icon>
                                                    <span v-if="item.geocoder.flight_travel_time_hours != 0" class="me-3 profile_icon-text">{{ item.geocoder.flight_travel_time_hours }}hrs</span>
                                                    <span v-else class="me-3 profile_icon-text">{{ item.geocoder.flight_travel_time_minutes }}mins</span>
                                                </div>
                                            </div>
                                            <div>Distance away</div>
                                            <span class="profile_icon-text">{{ item.geocoder.distance }} miles</span>
                                        </div>
                                        <div>
                                            <span 
                                            v-if="item.has_sent_request === true"
                                            >
                                                <v-chip
                                                outlined
                                                pill
                                                >
                                                Request Sent
                                                </v-chip>
                                            </span>
                                            <span v-else>
                                                <v-btn
                                                    v-if="item.has_received_request === true"
                                                    color="#6A994E"
                                                    dark
                                                    depressed
                                                    :loading="loading"
                                                    @click="sendMatch(item)"
                                                >
                                                    click to match
                                                </v-btn>
                                                <v-btn
                                                    v-else
                                                    color="#023E7D"
                                                    dark
                                                    depressed
                                                    :loading="loading"
                                                    @click="sendRequest(item)"
                                                >
                                                    Send Request
                                                </v-btn>
                                            </span>
                                        </div>
                                    </v-card-actions>
                                </v-card>
                                </v-row>
                            </v-sheet>
                            
                            </v-carousel-item>
                        </v-carousel>
                    </v-col>
                </v-row>
                
                
            </v-card-text>
            <v-card-actions>
            
            </v-card-actions>
        </v-card>
        </v-dialog> -->


        <!-- more info dialog -->
        <v-dialog
        v-model="infoDialog"
        max-width="650"
        >
        <v-card>
            <v-card-title class="card_title font-weight-bold">
            <span
            class="text-capitalize"
            >{{ viewedCase.case_type }}</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="py-6">
                <div class="pb-4">
                    <v-chip
                        class="mr-2 text-capitalize"
                        color="#DCE8F8"
                    >
                        {{viewedCase.why_need_attorney}}
                    </v-chip>
                </div>
                <v-row>
                    <v-col lg="4">
                        <h5>Case Name</h5>
                        <div class="case_text">
                            {{viewedCase.case_name}}
                        </div>
                    </v-col>
                    <v-col lg="4">
                        <h5>Practice Area</h5>
                        <div class="case_text">
                            <span
                            v-for="(item, i) in viewedCase.case_practice_area"
                            :key="i"
                            >{{item}}, </span>
                        </div>
                    </v-col>
                    <v-col lg="4">
                        <h5>Date of incident</h5>
                        <div class="case_text">{{viewedCase.date_of_incident | formatDate}}</div>
                    </v-col>
                    <v-col lg="6">
                        <h5>Location of incident</h5>
                        <div class="case_text">
                            {{viewedCase.location_incident}}
                        </div>
                    </v-col>
                    <v-col lg="12">
                        <h5>Case Details</h5>
                        <div class="case_text">
                            {{viewedCase.case_description}}
                        </div>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
            
            </v-card-actions>
        </v-card>
        </v-dialog>


        <!-- Retained dialog -->
        <v-dialog
        v-model="caseInfoDialog"
        max-width="650"
        >
        <v-card>
            <v-card-title class="py-1 card_title font-weight-bold">
                <v-list-item class="px-0">
                    <v-list-item-avatar rounded size="45" color="#DCE8F8">
                        <v-img v-if="selectedCaseDetails?.client?.image_url != 'img-cloud315r36bdhgevfg'" :src="selectedCaseDetails?.client?.image_url" alt="" accept="image/*" aspect-ratio="1.1"></v-img>
                    <v-icon color="#C3D6EF" dark>
                        mdi-account-outline
                    </v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                    <v-list-item-title class="text-capitalize">{{selectedCaseDetails?.client?.first_name}} {{selectedCaseDetails?.client?.last_name}}</v-list-item-title>
                    <v-list-item-subtitle class="case_text-caption">Client</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-menu
                            bottom
                            left
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                v-show="selectedCaseDetails?.status === 'retained'"
                                color="#023E7D"
                                icon
                                v-bind="attrs"
                                v-on="on"
                            >
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                            </template>

                            <v-list>
                            <v-list-item link @click="progressDialog = true">
                                <v-list-item-title>Update Progress</v-list-item-title>
                            </v-list-item>
                            <v-list-item link @click="newInvoiceDialog = true">
                                <v-list-item-title>New Invoice</v-list-item-title>
                            </v-list-item>
                            <v-list-item link @click="limitationDialog = true">
                                <v-list-item-title>Statute of limitation</v-list-item-title>
                            </v-list-item>
                            <v-list-item link>
                                <v-list-item-title>Dismiss Case</v-list-item-title>
                            </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-list-item-action>
                </v-list-item>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="py-6">
                <div class="mb-4 d-flex justify-start align-center">
                    <div>
                        <h5>Case Name</h5>
                        <span class="case_title text-capitalize">{{selectedCaseDetails?.case_name}}</span>
                    </div>
                    <v-chip
                        class="ms-2 text-capitalize"
                        color="#023E7D"
                        dark
                    >
                        {{selectedCaseDetails?.status}}
                    </v-chip>
                </div>
                <v-row>
                    <v-col lg="3">
                        <h5>Case Type</h5>
                        <div class="case_text text-capitalize">
                            {{ selectedCaseDetails?.case_type }}
                        </div>
                    </v-col>
                    <v-col lg="3">
                        <h5>Reason</h5>
                        <div class="case_text text-capitalize">{{selectedCaseDetails?.why_need_attorney}}</div>
                    </v-col>
                    <v-col lg="3">
                        <h5>Date of incident</h5>
                        <div class="case_text">
                            {{selectedCaseDetails?.date_of_incident | formatDate}}
                        </div>
                    </v-col>
                    <v-col lg="3">
                        <h5>Statute of limitation</h5>
                        <div v-if="'statute_of_limitation' in pickedcase" class="case_text">{{pickedcase?.statute_of_limitation | formatDate}}</div>
                        <div v-else class="case_text">Not Available</div>
                    </v-col>
                    <v-col lg="6">
                        <h5>Practice Area</h5>
                        <div class="case_text text-capitalize">
                            <span>{{selectedCaseDetails?.case_practice_area | commaSeparated}}</span>
                        </div>
                    </v-col>
                    <v-col lg="6">
                        <h5>Location of incident</h5>
                        <div class="case_text">
                            {{selectedCaseDetails?.location_incident}}
                        </div>
                    </v-col>
                    <v-col lg="12">
                        <h5>Case Details</h5>
                        <div class="case_text">
                            {{selectedCaseDetails?.case_description}}
                        </div>
                    </v-col>
                </v-row>
                <v-row v-if="selectedCaseDetails?.status === 'retained'">
                    <v-col cols="12" class="pt-4">
                        <h5 class="topic">SENT INVOICE</h5>
                        <v-list v-if="caseInvoices.length > 0">
                            <v-list-item 
                            v-for="(item, i) in caseInvoices"
                            :key="i"
                            link class="px-0 mb-2" 
                            @click="viewInvoice(item)"
                            >
                                <v-list-item-content class="py-0">
                                    <div class="attorney_card d-flex justify-space-between">
                                        <div class="attorney_card-box d-flex align-center">
                                            <v-avatar rounded size="45" color="#DCE8F8" class="me-4">
                                                <v-icon color="#C3D6EF" dark>
                                                mdi-receipt-text
                                                </v-icon>
                                            </v-avatar>
                                            <div>
                                                <h4 class="attorney_list-title">#{{ item.invoice_no }}</h4>
                                                <span class="attorney_list-subtitle">{{ item?.updatedAt | formatDate }}</span>
                                            </div>
                                        </div>

                                        <div class="mr-4 text-end">
                                            <h4 class="attorney_list-title">${{ item?.amount | amountFormat }}</h4>
                                            <span class="attorney_list-subtitle text-uppercase">{{ item?.status }}</span>
                                        </div>
                                    </div>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                        <div v-else>
                            There is no sent invoice currently
                        </div>
                    </v-col>
                </v-row>
                <v-row v-else-if="selectedCaseDetails?.status === 'open'">
                    <v-col cols="12" class="pa-4 d-flex justify-space-between align-center card_action">
                        <div>
                            <div class="mb-1 d-flex justify-start align-center">
                                <div v-show="pickedcase?.geocoder?.road_travel_time_hours != 'you may need to swim....'" class="d-flex align-center">
                                    <v-icon class="me-2 profile_icon" color="#7E889A">
                                        mdi-car
                                    </v-icon>
                                    <span v-if="pickedcase?.geocoder?.road_travel_time_hours != 0" class="me-3 profile_icon-text">{{ pickedcase?.geocoder?.road_travel_time_hours }}hrs</span>
                                    <span v-else class="me-3 profile_icon-text">{{ pickedcase?.geocoder?.road_travel_time_hours }}mins</span>
                                </div>
                                <div class="d-flex align-center">
                                    <v-icon class="me-2 profile_icon" color="#7E889A">
                                        mdi-airplane
                                    </v-icon>
                                    <span v-if="pickedcase?.geocoder?.flight_travel_time_hours != 0" class="me-3 profile_icon-text">{{ pickedcase?.geocoder?.flight_travel_time_hours }}hrs</span>
                                    <span v-else class="me-3 profile_icon-text">{{ pickedcase?.geocoder?.flight_travel_time_minutes }}mins</span>
                                </div>
                            </div>
                            <div>Distance away</div>
                            <span class="profile_icon-text">{{ pickedcase?.geocoder?.distance }} miles</span>
                        </div>
                        <div>
                            <span 
                            v-if="selectedCaseDetails?.has_sent_request === true && selectedCaseDetails?.has_received_request === true"
                            >
                                <v-chip
                                outlined
                                pill
                                >
                                Matched Made
                                </v-chip>
                            </span>
                            <span 
                            v-else-if="selectedCaseDetails?.has_sent_request === true"
                            >
                                <v-chip
                                outlined
                                pill
                                >
                                Request Sent
                                </v-chip>
                            </span>
                            <span v-else>
                                <v-btn
                                    v-if="selectedCaseDetails?.has_received_request === true"
                                    color="#29bf12"
                                    dark
                                    depressed
                                    rounded
                                    :loading="loading"
                                    @click="sendMatch(pickedcase)"
                                >
                                    click to match
                                    <v-icon
                                        right
                                        dark
                                    >
                                        mdi-handshake-outline
                                    </v-icon>
                                </v-btn>
                                <v-btn
                                    v-else
                                    color="#023E7D"
                                    dark
                                    depressed
                                    rounded
                                    :loading="loading"
                                    @click="sendRequest(pickedcase)"
                                >
                                    <v-icon
                                        left
                                        dark
                                    >
                                        mdi-hand-back-left-outline
                                    </v-icon>
                                    Send Request
                                </v-btn>
                            </span>
                        </div> 
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        </v-dialog>


        <!-- case progress -->
        <v-dialog
        v-model="progressDialog"
        max-width="750"
        >
        <v-card>
            <v-card-title class="card_title font-weight-bold">
            Case Progress
            <v-spacer></v-spacer>
            <v-btn 
            v-show="selectedCaseDetails.case_progress_state == 'ongoing'"
            color="#023E7D" 
            text 
            depressed
            @click="openCreateProgress"
            >
            Add progress
            </v-btn>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-8 pb-4">
                <div class="mb-2 attorney_card-box d-flex align-center">
                    <v-avatar rounded size="45" color="#DCE8F8" class="me-4">
                        <v-icon color="#C3D6EF" dark>
                        mdi-briefcase
                        </v-icon>
                    </v-avatar>
                    <div>
                        <h4 class="attorney_list-title">{{selectedCaseDetails?.client?.first_name}} {{selectedCaseDetails?.client?.last_name}}</h4>
                        <span class="attorney_list-subtitle">{{pickedcase.case_type}} Case</span>
                    </div>
                </div>
                <div class="progressTab">
                    <v-chip
                        class="mr-2"
                        :color="tabProgress === 0 ? '#099FB2' : '#B5E2E8'"
                        :text-color="tabProgress === 0 ? 'white' : 'grey darken-2'"
                        @click="tabProgress = 0"
                    >
                        <v-icon left size="15">mdi-help-circle-outline</v-icon>
                        View suggested workflow
                    </v-chip>
                    <v-chip
                        class="pe-0"
                        :color="tabProgress === 1 ? '#099FB2' : '#B5E2E8'"
                        :text-color="tabProgress === 1 ? 'white' : 'grey darken-2'"
                        @click="tabProgress = 1"
                    >
                        Progress tracking
                        <v-chip
                        v-if="selectedCaseDetails.case_progress_state != 'ongoing'"
                        :color="selectedCaseDetails.case_progress_state != 'aborted' ? '#023E7D' :'#d90429'"
                        class="ms-2 text-capitalize"
                        dark
                        >
                        {{ selectedCaseDetails.case_progress_state }}
                        </v-chip>
                        <span v-else class="me-2"></span>
                    </v-chip>
                </div>
                <v-responsive
                    class="overflow-y-auto px-4"
                    max-height="600"
                    >
                        <v-responsive>
                            <v-tabs-items v-model="tabProgress">
                                <v-tab-item>
                                    <p class="mb-8 mt-4">Dive into your case with confidence, learn about the step-by-step process below to understand every aspect.</p>
                                    <v-row>
                                        <v-col lg="12">
                                            <div v-if="defaultSteps?.length === 0" class="text-center d-flex justify-center">
                                                <v-card flat width="400" height="250" class="text-center d-flex align-center">
                                                    <div>
                                                        <v-icon
                                                        size="100"
                                                        color="#D4D4D4"
                                                        >
                                                        mdi-package-variant
                                                        </v-icon>
                                                        <p class="placeholder_text">Nothing to see here! You don't have any case, create a case and it will show up on this list.</p>
                                                    </div>
                                                </v-card>
                                            </div>
                                            <v-timeline
                                            v-else
                                            align-top
                                            dense
                                            >
                                                <v-timeline-item
                                                v-for="(item, i) in defaultSteps"
                                                :key="i"
                                                color="#023E7D"
                                                fill-dot
                                                small
                                                >
                                                <div class="py-4">
                                                    <h3>
                                                    {{item.title}}
                                                    </h3>
                                                    <span class="mb-2">{{ item.case_type }} Case Step {{ item.step }}</span>
                                                    <div>
                                                    {{item.description}}
                                                    </div>
                                                    <div class="text-end">
                                                        <v-tooltip right>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn
                                                                icon
                                                                color="#023E7D"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                :loading="checkLoader"
                                                                @click="copyProgress(item)"
                                                                >
                                                                <v-icon small>mdi-content-copy</v-icon>
                                                                </v-btn>
                                                            </template>
                                                            Copy step details
                                                        </v-tooltip>
                                                    </div>
                                                </div>
                                                </v-timeline-item>
                                            </v-timeline>
                                        </v-col>
                                    </v-row>
                                </v-tab-item>
                                <v-tab-item>
                                    <p class="mb-8 mt-4">Please pick any of the available days on the calender, note the days set by the attorney.</p>
                                    <v-row>
                                        <v-col lg="12">
                                            <v-timeline
                                                v-if="'case_progress' in pickedcase"
                                                align-top
                                                dense
                                            >
                                                <v-timeline-item
                                                v-for="(item, i) in pickedcase.case_progress"
                                                :key="i"
                                                :icon="item.is_completed ? 'mdi-check' : 'mdi-dot'"
                                                color="#023E7D"
                                                small
                                                >
                                                <v-row class="pt-1">
                                                    <v-col>
                                                        <div class="d-flex justify-start align-center">
                                                            <h2>
                                                            {{item.title}}
                                                            </h2>
                                                            <v-chip
                                                            color="#023E7D"
                                                            class="ms-2"
                                                            dark
                                                            >
                                                                Step {{ item.step }}
                                                            </v-chip>
                                                            <div
                                                            v-if="selectedCaseDetails.case_progress_state == 'ongoing'"
                                                            >
                                                                <v-btn
                                                                icon
                                                                class="ms-1"
                                                                color="#023E7D"
                                                                :disabled="item.is_completed"
                                                                :loading="editLoader"
                                                                @click="editProgress(item)"
                                                                >
                                                                <v-icon>mdi-pencil-outline</v-icon>
                                                                </v-btn>
                                                                <v-btn
                                                                icon
                                                                class="ms-1"
                                                                color="#023E7D"
                                                                :disabled="item.is_completed"
                                                                :loading="checkLoader"
                                                                @click="checkProgress(item)"
                                                                >
                                                                <v-icon>mdi-check</v-icon>
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                        <div class="mt-2">
                                                        {{item.notes}}
                                                        </div>
                                                    </v-col>
                                                </v-row>
                                                </v-timeline-item>
                                            </v-timeline>
                                            <div v-else class="text-center d-flex justify-center">
                                                <v-card flat width="400">
                                                    <v-icon
                                                    size="100"
                                                    color="#D4D4D4"
                                                    >
                                                    mdi-package-variant
                                                    </v-icon>
                                                    <p class="placeholder_text">Nothing to see here! There's no case progress, click the add progress button above to add progress.</p>
                                                </v-card>
                                            </div>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-alert
                                            v-if="selectedCaseDetails.case_progress_state == 'aborted'"
                                            text
                                            dense
                                            prominent
                                            type="error"
                                            icon="mdi-alert-circle"
                                            >
                                            This client dismissed you from this case, therefore the case progress was aborted.
                                            </v-alert>
                                            <v-alert
                                            v-else-if="selectedCaseDetails.case_progress_state == 'completed'"
                                            text
                                            dense
                                            prominent
                                            type="success"
                                            icon="mdi-check-circle"
                                            >
                                            You updated this case to be completed, and we have notified the client.
                                            </v-alert>
                                            <v-btn
                                            v-else-if="selectedCaseDetails.case_progress_state == 'ongoing'"
                                            dark
                                            rounded
                                            depressed
                                            color="#023E7D"
                                            :loading="completedLoader"
                                            @click="caseCompleted"
                                            >
                                            Case Progress Completed
                                            <v-icon
                                                right
                                                dark
                                            >
                                                mdi-progress-check
                                            </v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-tab-item>
                            </v-tabs-items>
                        </v-responsive>
                </v-responsive>
            </v-card-text>
        </v-card>
        </v-dialog>

        <!-- new invoice appointments -->
        <v-dialog
        v-model="newInvoiceDialog"
        max-width="700"
        >
        <v-card>
            <div class="title_bg">
                <v-card-title class="card_title font-weight-bold">
                Invoice
                </v-card-title>
            </div>
            <v-card-text class="mt-8">
                <v-row>
                    <v-col lg="6">
                        <h5>Client Name</h5>
                        <div class="case_text">
                            {{clientDetails.first_name}} {{clientDetails.last_name}}
                        </div>
                    </v-col>
                    <v-col lg="6">
                        <h5>Case ID</h5>
                        <div class="case_text">
                            #{{ pickedcase._id }}
                        </div>
                    </v-col>
                    <v-col lg="6">
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            :return-value.sync="date"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="date"
                                label="Date"
                                append-icon="calendar-blank-outline"
                                filled
                                readonly
                                class="some-style"
                                hide-details="auto"
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="date"
                            no-title
                            scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="menu = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menu.save(date)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col lg="6">
                        <v-menu
                            ref="menudue"
                            v-model="menudue"
                            :close-on-content-click="false"
                            :return-value.sync="dueDate"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="dueDate"
                                label="Due Date"
                                append-icon="calendar-blank-outline"
                                filled
                                readonly
                                class="some-style"
                                hide-details="auto"
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="dueDate"
                            no-title
                            scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="menudue = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menudue.save(dueDate)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col lg="6">
                        <v-textarea
                        v-model="terms"
                        filled
                        clearable
                        clear-icon="mdi-close-circle"
                        rows="3"
                        label="Terms"
                        class="some-style"
                        hide-details="auto"
                        ></v-textarea>
                    </v-col>
                    <v-col lg="6">
                        <v-textarea
                        v-model="note"
                        filled
                        clearable
                        clear-icon="mdi-close-circle"
                        rows="3"
                        label="Note"
                        class="some-style"
                        hide-details="auto"
                        ></v-textarea>
                    </v-col>
                    <v-col cols="12">
                        <v-simple-table dense>
                            <template v-slot:default>
                            <thead>
                                <tr>
                                <th class="text-left">
                                    Description
                                </th>
                                <th class="text-left">
                                    Cost
                                </th>
                                <th class="text-left"></th>
                                </tr>
                            </thead>
                            <tbody
                            v-if="itemList.length > 0"
                            >
                                <tr
                                v-for="(item, i) in itemList"
                                :key="i"
                                >
                                <td>{{ item.description }}</td>
                                <td>${{ item.cost | amountFormat }}</td>
                                <td>
                                    <v-btn
                                    icon
                                    small
                                    color="#023E7D"
                                    @click="deleteItem(item)"
                                    >
                                    <v-icon small>mdi-trash-can-outline</v-icon>
                                    </v-btn>
                                </td>
                                </tr>
                            </tbody>
                            <div
                            v-else 
                            class="mt-4"
                            >
                            <v-card
                            flat 
                            width="480"

                            class="d-flex jusity-start align-center"
                            >
                                <v-icon
                                size="50"
                                color="#D4D4D4"
                                class="me-2"
                                >
                                mdi-view-list-outline
                                </v-icon>
                                <span class="placeholder_text">There's currently no service item to display, click the add service button below to add</span>
                            </v-card>
                            </div>
                            </template>
                        </v-simple-table>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-end">
                        <v-btn text color="#023E7D" @click="addService">
                            Add service
                        </v-btn>
                    </v-col>
                    <v-col cols="12">
                        <span class="text_caption me-2">Total</span>
                        <span class="text_bold">${{ total | amountFormat }}</span>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-end">
                        <v-btn 
                        color="#023E7D" 
                        @click="createPreview"
                        depressed 
                        dark
                        >
                            Continue
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        </v-dialog>


        <!-- invoice preview -->
        <v-dialog
        v-model="invoicePreview"
        max-width="700"
        >
        <v-card>
            <div class="title_bg">
                <v-card-title class="card_title font-weight-bold">
                Invoice Preview
                </v-card-title>
            </div>
            <v-card-text class="mt-8">
                <v-row>
                    <v-col lg="4">
                        <h5>Client Name</h5>
                        <div class="case_text">
                            {{clientDetails.first_name}} {{ clientDetails.last_name }}
                        </div>
                    </v-col>
                    <v-col lg="4">
                        <h5>Case ID</h5>
                        <div class="case_text">
                            #{{ pickedcase._id }}
                        </div>
                    </v-col>
                    <v-col lg="4">
                        <h5>Date</h5>
                        <div class="case_text">
                            {{ date | formatDate }}
                        </div>
                    </v-col>
                    <v-col lg="4">
                        <h5>Due Date</h5>
                        <div class="case_text">
                            {{ dueDate | formatDate }}
                        </div>
                    </v-col>
                    <v-col lg="8">
                        <h5>Terms</h5>
                        <div class="case_text">
                            {{ terms }}
                        </div>
                    </v-col>
                    <v-col lg="12">
                        <h5>Note</h5>
                        <div class="case_text">
                            {{ note }}
                        </div>
                    </v-col>
                    <v-col cols="12">
                        <v-simple-table dense>
                            <template v-slot:default>
                            <thead>
                                <tr>
                                <th class="text-left">
                                    Description
                                </th>
                                <th class="text-left">
                                    Cost
                                </th>
                                </tr>
                            </thead>
                            <tbody v-if="itemList.length > 0">
                                <tr
                                v-for="(item, i) in itemList"
                                :key="i"
                                >
                                <td>{{ item.description }}</td>
                                <td>${{ item.cost | amountFormat }}</td>
                                </tr>
                            </tbody>
                            <div
                            v-else 
                            class="mt-4"
                            >
                            <v-card
                            flat 
                            width="480"

                            class="d-flex jusity-start align-center"
                            >
                                <v-icon
                                size="50"
                                color="#D4D4D4"
                                class="me-2"
                                >
                                mdi-view-list-outline
                                </v-icon>
                                <span class="placeholder_text">There's currently no service item to display, click the add service button below to add</span>
                            </v-card>
                            </div>
                            </template>
                        </v-simple-table>
                    </v-col>
                    <v-col cols="12">
                        <span class="text_caption me-2">Total</span>
                        <span class="text_bold">${{ total | amountFormat }}</span>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-end">
                        <v-btn 
                        block
                        depressed
                        dark
                        color="#023E7D" 
                        :loading="invoiceLoader"
                        @click="invoiceSetup"
                        >
                        send invoice
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        </v-dialog>

        <!-- invoice preview -->
        <v-dialog
        v-model="previewInvoice"
        max-width="700"
        >
        <v-card>
            <v-card-title class="card_title font-weight-bold">
            Invoice
            </v-card-title>
            <v-card-text class="mt-8">
                <v-row>
                    <v-col lg="4">
                        <h5>Client Name</h5>
                        <div class="case_text">
                            {{previewInvoiceDetails.client_name}}
                        </div>
                    </v-col>
                    <v-col lg="4">
                        <h5>Case ID</h5>
                        <div class="case_text">
                            #{{ previewInvoiceDetails.case_id }}
                        </div>
                    </v-col>
                    <v-col lg="4">
                        <h5>Date</h5>
                        <div class="case_text">
                            {{ previewInvoiceDetails.createdAt | formatDate }}
                        </div>
                    </v-col>
                    <v-col lg="4">
                        <h5>Due Date</h5>
                        <div class="case_text">
                            {{ previewInvoiceDetails.due_date | formatDate }}
                        </div>
                    </v-col>
                    <v-col lg="8">
                        <h5>Case Type</h5>
                        <div class="case_text">
                            {{ previewInvoiceDetails.service_rendered }}
                        </div>
                    </v-col>
                    <v-col lg="12">
                        <h5>Note</h5>
                        <div class="case_text">
                            {{ previewInvoiceDetails.comment }}
                        </div>
                    </v-col>
                    <v-col cols="12">
                        <v-simple-table dense>
                            <template v-slot:default>
                            <thead>
                                <tr>
                                <th class="text-left">
                                    Description
                                </th>
                                <th class="text-left">
                                    Cost
                                </th>
                                </tr>
                            </thead>
                            <tbody v-if="previewInvoiceDetails.services.length > 0">
                                <tr
                                v-for="(item, i) in previewInvoiceDetails.services"
                                :key="i"
                                >
                                <td>{{ item.description }}</td>
                                <td>${{ item.cost | amountFormat }}</td>
                                </tr>
                            </tbody>
                            <div
                            v-else 
                            class="mt-4"
                            >
                            <v-card
                            flat 
                            width="480"

                            class="d-flex jusity-start align-center"
                            >
                                <v-icon
                                size="50"
                                color="#D4D4D4"
                                class="me-2"
                                >
                                mdi-view-list-outline
                                </v-icon>
                                <span class="placeholder_text">There's was no service item entered, that's why this list is empty.</span>
                            </v-card>
                            </div>
                            </template>
                        </v-simple-table>
                    </v-col>
                    <v-col cols="12">
                        <span class="text_caption me-2">Total</span>
                        <span class="text_bold">${{ previewInvoiceDetails.amount | amountFormat }}</span>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>
        </v-dialog>

        <!-- Add Statute Of Limitation -->
        <v-dialog
        v-model="limitationDialog"
        max-width="340"
        >
        <v-card>
            <v-card-title>
            Add Statute Of Limitation
            </v-card-title>
            <v-card-text>
                <span>By adding this you will get a reminder when the expiration is coming due.</span>
                <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    :return-value.sync="date2"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="date2"
                        label="MMDDYY"
                        append-icon="mdi-calendar-blank-outline"
                        filled
                        dense
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        class="mt-2 some-style"
                    ></v-text-field>
                    </template>
                    <v-date-picker
                    v-model="date2"
                    no-title
                    scrollable
                    >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="menu2 = false"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="$refs.menu2.save(date2)"
                    >
                        OK
                    </v-btn>
                    </v-date-picker>
                </v-menu>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="#053669"
                dark
                elevation="0"
                :loading="limitationLoader"
                @click="submitLimitation"
            >
                Send
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>

        <!-- Add progress -->
        <v-dialog
        v-model="progressNoteDialog"
        max-width="340"
        persistent
        >
        <v-card>
            <v-form
            ref="progressForm"
            v-model="progressFormValid"
            lazy-validation
            >
            <v-card-title>
            Add Progress
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col 
                    cols="12"
                    v-if="addTitleState === false"
                    >
                        <v-autocomplete
                            v-model="progressTitle"
                            :items="defaultSteps"
                            dense
                            filled
                            hide-no-data
                            hide-details="auto"
                            value="title"
                            item-text="title"
                            label="Title"
                            class="some-style"
                        >
                        <template v-slot:item="{item}">
                            <v-tooltip right>
                                <template v-slot:activator="{ on, attrs }">
                                    <span 
                                    class="text-capitalize"
                                    v-bind="attrs"
                                    v-on="on"
                                    >{{ item.title }}</span>
                                </template>
                                <div
                                class="tipContent"
                                >
                                    <span>{{ item.description }}</span>
                                </div>
                            </v-tooltip>
                        </template>
                        <template v-slot:append-item>
                            <v-btn 
                            block
                            elevation="0"
                            class="mt-2"
                            @click="addTitle"
                            >
                            Add
                            </v-btn>
                        </template>
                        </v-autocomplete>
                    </v-col>
                    <v-col 
                    cols="12"
                    v-else 
                    class="mb-4 d-flex justify-start align-center"
                    >
                        <v-text-field
                            v-model="progressTitle"
                            filled
                            dense
                            label="Enter Title"
                            class="me-2 some-style"
                            hide-details="auto"
                        ></v-text-field>
                        <v-btn
                        icon
                        color="grey"
                        @click="addTitleState = false"
                        >
                        <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="4" class="d-flex align-end">
                        <v-text-field
                            v-model="stepCasePro"
                            filled
                            dense
                            label="Step"
                            class="some-style"
                            hide-details="auto"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="8">
                        <v-menu
                            ref="menu3"
                            v-model="menu3"
                            :close-on-content-click="false"
                            :return-value.sync="date3"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="date3"
                                label="Date Completed"
                                filled
                                dense
                                readonly
                                hide-details="auto"
                                v-bind="attrs"
                                v-on="on"
                                class="mt-2 some-style"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            v-model="date3"
                            no-title
                            scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="menu3 = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                color="primary"
                                @click="$refs.menu3.save(date3)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12">
                        <v-textarea
                        v-model="progressNote"
                        name="input-7-1"
                        filled
                        label="Note"
                        hide-details="auto"
                        class="some-style"
                        ></v-textarea>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions class="px-4">
            <v-btn
            color="#053669"
            outlined
            @click="closeProgress"
            >
                Close
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                color="#053669"
                dark
                elevation="0"
                :loading="progressLoader"
                @click="submitProgress"
            >
                Send
            </v-btn>
            </v-card-actions>
            </v-form>
        </v-card>
        </v-dialog>

        <v-dialog
        v-model="addServiceDialog"
        max-width="350"
        >
        <v-card>
            <v-card-title>
            Add Service
            </v-card-title>
            <v-card-text>
                <v-text-field
                v-model="description"
                label="Description"
                filled
                dense
                class="some-style"
                ></v-text-field>
                <v-text-field
                v-model="cost"
                label="Cost"
                type="number"
                filled
                dense
                hide-details="auto"
                class="some-style"
                ></v-text-field>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="#053669"
                text
                @click="addItem"
            >
                Add
            </v-btn>
            </v-card-actions>
        </v-card>
        </v-dialog>

        <!-- snackbar -->
        <v-snackbar
        v-model="snackbar"
        :color="bgColor"
        top
        right
        >
        {{ message }}
        <template v-slot:action="{ attrs }">
            <v-btn
                color="white"
                text
                v-bind="attrs"
                @click="snackbar = false"
            >
                Close
            </v-btn>
        </template>
        </v-snackbar>
    </div>
</template>

<script>
import ApptCard from '../../components/attorney/AppointmentCardAttorney.vue'
import TranCard from '../../components/attorney/TransactionCardAttorney.vue'
import Sidebar from '../../components/attorney/SidebarAttorney.vue'
import Navbar from '../../components/attorney/NavbarAttorney.vue'

export default {

    components: {
        ApptCard, TranCard, Sidebar, Navbar
    },

    data () {
      return {
        NewCasesDialog: false,
        infoDialog: false,
        CaseDialog: false,
        caseInfoDialog: false,
        progressDialog: false,
        newInvoiceDialog: false,
        moreInfo: false,
        invoicePreview: false,
        previewInvoice: false,
        attorneySlide: 0,
        menu: false,
        menudue: false,
        loading: false,
        snackbar: false,
        message: '',
        bgColor: '',
        date: new Date().toISOString().substr(0, 10),
        dueDate: new Date().toISOString().substr(0, 10),
        tab: 0,
        tabProgress: 1,
        viewedCase: {},
        pickedcase: {},
        limitationDialog: false,
        menu2: false,
        date2: null,
        menu3: false,
        date3: null,
        limitationLoader: false,
        progressNoteDialog: false,
        progressTitle: '',
        progressNote: '',
        progressLoader: false,
        listLoader: false,
        clientDetails: {},
        addServiceDialog: false,
        description: '',
        cost: '',
        itemList: [],
        addDialog: false,
        terms: '',
        note: '',
        invoiceLoader: false,
        defaultSteps: [],
        checkLoader: false,
        editLoader: false,
        addTitleState: false,
        selectedCaseStep: null,
        stepCasePro: null,
        caseInvoices: [],
        previewInvoiceDetails: {},
        wsReady: false,
        newMessage: '',
        messages: [],
        ratingDialog: false,
        selectedType: 0,
        caseType: ['all cases', 'matched', 'retained', 'open', 'closed', 'completed'],
        search: '',
        type: '',
        sort: false,
        page: 1,
        limit: 10,
        totalPages: 3,
        caseLoader: false,
        selectedCaseDetails: {},
        loadingState: {},
        completedLoader: false,
        progressFormValid: true,
        progressIsEdit: false,
        progressIsEditId: ''
      }
    },

    watch: {
        selectedType(v) {
            if(v != 0) {
                this.type = this.caseType[v]
            } else {
                this.type = ''
            }
            this.getAllCases()
        },
        page() {
            this.getAllCases()
        },
        sort() {
            this.getAllCases()
        },
        search(val) {
            this.getAllCases(val);
        },
        
    },

    methods: {
        openCreateProgress() {
            this.progressNoteDialog = true
            this.progressIsEdit = false
        },
        openCard() {
            this.selectedType = 3
        },
        matchedCard() {
            this.selectedType = 1
        },
        retainedCard() {
            this.selectedType = 2
        },
        caseCompleted() {
            this.completedLoader = true;
            return this.$store.dispatch('updateCaseStatus', {
                caseId: this.selectedCaseDetails._id,
            })
            .then(() => {
                this.completedLoader = false;
                this.updateSelectedCase()
            })
            .catch((error) => {
                this.completedLoader = false;
                console.error(error);
                this.snackbar = true;
                this.message = error.response?.data?.error || 'An error occurred';
                this.bgColor = 'error';
            });
        },

        getAllCases(v) {
            this.caseLoader = true;
            return this.$store.dispatch('getAttorneyCases', {
                status: this.type,
                search: v === undefined ? '' : v,
                page: this.page,
                limit: this.limit,
                order: this.sort ? 'createdAt,desc' : 'createdAt,asc',
            })
            .then(() => {
                this.caseLoader = false;
            })
            .catch((error) => {
                this.caseLoader = false;
                console.error(error);
                this.snackbar = true;
                this.message = error.response?.data?.error || 'An error occurred';
                this.bgColor = 'error';
            });
        },

        viewInvoice(item) {
            console.log(item)
            this.previewInvoice = true
            this.previewInvoiceDetails = item
        },

        copyProgress(item) {
            console.log(item)
            this.selectedCaseStep = null
            this.stepCasePro = item.step,
            this.progressTitle = item.title,
            this.progressNote = item.description,
            this.date3 = '',
            this.progressNoteDialog = true
        },

        addTitle() {
            this.addTitleState = true
        },

        editProgress(item) {
            console.log(item)
            this.progressNoteDialog = true
            this.selectedCaseStep = item
            this.progressIsEdit = true
            this.progressIsEditId = item._id
            this.stepCasePro = item.step
            this.progressTitle = item.title
            this.progressNote = item.notes
        },

        updateSelectedCase() {
            this.$store.dispatch('attorneyGetCaseById', this.selectedCaseDetails._id)
            .then((response) => {
                this.pickedcase = response.data.data.case;
                // this.progressNoteDialog = false
            })
            .catch((error) => {
                this.limitationLoader = false
                this.snackbar = true
                this.message = error.response.data.error
                this.bgColor = 'error'
            })
        },

        editCaseProgress() {
            console.log(this.selectedCaseStep)
            this.editLoader= true
            this.$store.dispatch('editCaseProgress', {
                step: this.stepCasePro,
                notes: this.progressNote,
                title: this.progressTitle,
                date: this.date3,
                id: this.selectedCaseDetails._id
            })
            .then((response) => {
                this.progressNoteDialog = false
                this.progressIsEdit = false
                this.editLoader= false
                this.snackbar = true
                this.message = 'Step completed'
                this.bgColor = 'success'
                this.selectedCaseDetails.case_progress = response.data.data.case_progress
                this.pickedcase.case_progress = response.data.data.case_progress
                this.$refs.progressForm.reset();
                this.getAllCases()
            })
            .catch((error) => {
                this.editLoader= false
                this.snackbar = true
                this.message = error.response.data.error
                this.bgColor = 'error'
            })
        },

        checkProgress(item) {
            console.log(item)
            this.checkLoader= true
            this.$store.dispatch('checkCaseProgress', {
                id: this.selectedCaseDetails._id,
                step: item.step,
                is_completed: true
            })
            .then((response) => {
                this.selectedCaseDetails.case_progress = response.data.data.case_progress
                this.pickedcase.case_progress = response.data.data.case_progress
                this.checkLoader= false
                this.snackbar = true
                this.message = 'Step completed'
                this.bgColor = 'success'
                this.getAllCases()
            })
            .catch((error) => {
                this.checkLoader= false
                this.snackbar = true
                this.message = error.response.data.error
                this.bgColor = 'error'
            })
        },

        deleteItem (item) {
            let listIndex = this.itemList.indexOf(item)
            this.itemList.splice(listIndex, 1)
        },

        addService() {
            this.addServiceDialog = true
        },

        addItem() {
            this.itemList.push({
                description: this.description,
                cost: this.cost
            })
            this.description = ''
            this.cost = ''
        },

        createPreview() {
            this.newInvoiceDialog = false
            this.invoicePreview = true
        },

        invoiceSetup() {
            this.invoiceLoader= true
            this.$store.dispatch('createInvoice', {
                case_id: this.selectedCaseDetails._id,
                amount: this.total,
                service_rendered: this.pickedcase.case_type,
                service_hour: 0,
                client_name: `${this.clientDetails.first_name} ${this.clientDetails.last_name}`,
                client_address: this.clientDetails.state,
                client_email: this.clientDetails.email,
                client_phone: this.clientDetails.phone,
                comment: this.note,
                due_date: this.dueDate,
                services: this.itemList
            })
            .then((response) => {
                console.log(response)
                this.invoiceLoader= false
                this.snackbar = true
                this.message = response.data.message
                this.bgColor = 'success'
                this.invoicePreview = false
                this.newInvoiceDialog = false
            })
            .catch((error) => {
                this.invoiceLoader= false
                this.snackbar = true
                this.message = error.response.data.error
                this.bgColor = 'error'
            })
        },

        selectedCase(item, index) {
            this.$set(this.loadingState, index, true);
            console.log('selected case')
            console.log(item)
            this.selectedCaseDetails = item
            if(item.status == 'retained') {
                this.listLoader = true
                this.$store.dispatch('attorneyGetCaseById', item._id)
                .then((response) => {
                    this.$set(this.loadingState, index, false);
                    this.listLoader = false
                    this.pickedcase = response.data.data.case;
                    console.log('pickedcase')
                    console.log(this.pickedcase)
                    Object.assign(this.pickedcase, {status: item.status});
                    this.defaultSteps = response.data.data.default_steps;
                    this.clientDetails = item.client
                    this.caseDetailsDialog()
                })
                .catch((error) => {
                    this.limitationLoader = false
                    this.listLoader = false
                    this.snackbar = true
                    this.message = error.response.data.error
                    this.bgColor = 'error'
                })

                this.$store.dispatch('getInvoiceByCaseId', item._id)
                .then((response) => {
                    this.listLoader = false
                    this.caseInvoices = response.data.data;
                    this.$set(this.loadingState, index, false);
                    this.caseDetailsDialog()
                    console.log('picked case invoice')
                    console.log(response)
                })
                .catch((error) => {
                    this.limitationLoader = false
                    this.listLoader = false
                    this.snackbar = true
                    this.message = error.response.data.error
                    this.bgColor = 'error'
                })
            } else {
                this.$set(this.loadingState, index, false);
                this.pickedcase = item
                this.caseDetailsDialog()
                console.log('pickedcase')
                console.log(this.pickedcase)
                
            }
        },

        caseDetailsDialog() {
            this.caseInfoDialog = true;
        },

        submitLimitation() {
            this.limitationLoader = true
            this.$store.dispatch('sendLimitation', {
                caseId: this.selectedCaseDetails._id,
                date: this.date2
            })
            .then((response) => {
                this.limitationLoader = false
                this.snackbar = true
                this.message = response.data.message
                this.bgColor = 'success'
                this.limitationDialog = false
                this.updateSelectedCase()
                this.getAllCases()
            })
            .catch((error) => {
                this.limitationLoader = false
                this.snackbar = true
                this.message = error.response.data.error
                this.bgColor = 'error'
            })
        },

        closeProgress() {
            this.progressNoteDialog = false
            this.progressIsEdit = false
            this.$refs.progressForm.reset();
        },

        submitProgress() {
            let formstatus = this.$refs.progressForm.validate()
            if(formstatus != true) return
            console.log(this.selectedCaseStep)
            if(this.progressIsEdit != true) {
                this.progressLoader = true
                this.$store.dispatch('sendProgress', {
                    caseId: this.selectedCaseDetails._id,
                    step: this.stepCasePro,
                    title: this.progressTitle,
                    notes: this.progressNote,
                    date: this.date3,
                })
                .then((response) => {
                    this.progressLoader = false
                    this.snackbar = true
                    this.message = response.data.message
                    this.bgColor = 'success'
                    this.progressNoteDialog = false
                    this.$refs.progressForm.reset();
                    this.selectedCaseDetails.case_progress = response.data.data.case_progress
                    this.pickedcase.case_progress = response.data.data.case_progress
                    this.getAllCases()
                })
                .catch((error) => {
                    this.progressLoader = false
                    this.snackbar = true
                    this.message = error.response.data.error
                    this.bgColor = 'error'
                })
            } else {
                this.editCaseProgress()
            }
        },

        sendRequest(item) {
            console.log(item)
            console.log(this.selectedCaseDetails)
            this.loading = true
            this.$store.dispatch('sendAttorneyRequest', item._id)
            .then((response) => {
                this.loading = false
                this.snackbar = true
                this.message = response.data.message
                this.bgColor = 'success'
                this.selectedCaseDetails.has_sent_request = true
                // this.$store.dispatch('getAttorneyCases')
            })
            .catch((error) => {
                this.loading = false
                this.snackbar = true
                this.message = error.response.data.error
                this.bgColor = 'error'
            })
        },

        sendMatch(item) {
            console.log(item)
            this.loading = true
            this.$store.dispatch('acceptClientRequest', item._id)
            .then((response) => {
                this.loading = false
                this.snackbar = true
                this.message = response.data.message
                this.bgColor = 'success'
                this.NewCasesDialog = false
                this.selectedCaseDetails.has_sent_request = true
                // this.$store.dispatch('getAttorneyCases')
            })
            .catch((error) => {
                this.loading = false
                this.snackbar = true
                this.message = error.response.data.error
                this.bgColor = 'error'
            })
        },

        viewItem(item) {
            this.viewedCase  = item
            this.infoDialog = true
        },

    },

    mounted () {
        this.getAllCases()
    },

    computed: {
        attorneyCases() {
            return this.$store.state.attorneyCases;
        },

        total() {
            return this.itemList.reduce((acc, ele) => {
            return acc + parseFloat(ele.cost);
            }, 0);
        }
    }

  }
</script>

<style scoped>
    .topic {
        color: #023E7D;
    }
    .subtopic {
        color: #7E889A;
    }
    .card_figure {
        color: #023E7D;
        font-size: 25px;
        margin-bottom: 8px;
    }
    .card_figure-text {
        color: #023E7D;
    }
    .attorney_card {
        background-color: #FBFBFB;
        border-radius: 5px;
        padding: 10px;
    }
    .attorney_card-box {
        height: 34px;
    }
    .some-style >>> .v-input__slot::before {
        border-style: none !important;
    }
    .line {
        width: 1px;
        height: 20px;
        background-color: #FFFFFF;
        margin-bottom: 5px;
        margin-left: 10px;
    }
    .badge {
        padding: 4px 8px;
        background-color: #FFFFFF;
        color: #023E7D;
        font-weight: bold;
        border-radius: 100%;
    }
    .card_dheading {
        color: #FFFFFF;
        margin-top: 25px;
        margin-left: 8px;
    }
    .card_lheading {
        color: #023E7D;
    }
    .card_lsubheading {
        color: #585F6C;
        font-family: 'Poppins', sans-serif;
        font-size: 12px;
    }
    .card_dsubheading {
        color: #FFFFFF;
        font-size: 12px;
    }
    .body_llink {
        color: #023E7D;
        font-size: 14px;
        text-decoration: none;
    }
    .body_dlink {
        color: #FFFFFF;
        font-size: 14px;
        text-decoration: none;
    }
    .card_header {
        background-color: #023E7D;
    }
    .title_bg {
        background-color: #023E7D;
        color: #fff;
    }
    .text_caption {
        color: #023E7D;
        font-size: 12px;
    }
    .text_bold {
        color: #023E7D;
        font-size: 20px;
        font-weight: 600;
        font-family: 'Poppins', sans-serif;
    }
    .prospective_figure {
        color: #FFF;
        font-weight: 700;
        font-size: 20px;
        line-height: .5;
        margin-bottom: 4px;
        margin-top: 6px;
    }
    .prospective_text {
        color: #FFF;
        font-size: 11px;
    }
    .help_text {
        color: #FFF;
        font-size: 11px;
        font-weight: 600;
        line-height: 1.5;
        margin-bottom: 7px;
    }
    .placeholder_text {
        color: #A7A7A7;
    }
    .skeleton_card {
        max-width: 400px;
        margin: auto;
    }
    .profile_title {
        font-size: 25px;
        color: #023047;
    }
    .profile_icon {
        margin-top: -4px;
    }
    .profile_icon-text {
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: bold;
        color: #023E7D;
    }
    .progressTab {
        margin-top: 40px;
    }
    .tipContent {
        width: 300px;
    }
    .right_sidebar {
        border-left: 1px solid #e9ecef;
    }
    .search_input {
        width: 350px;
    }
    .case_title {
        font-size: 20px;
    }
    .case_text-caption {
        font-size: 13px;
        font-weight: 300;
        color: #A7A7A7;
    }
    .case_link {
        color: #000;
        font-weight: 400;
    }
    .card_action {
        background-color: #fbf8cc;
        border-radius: 20px;
    }
</style>